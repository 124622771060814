<template>
    <div>
        <v-row class="-mt-8">
            <v-col cols="12" :class="$vuetify.breakpoint.smAndUp ? 'text-center pt-12' : '' ">
                <v-form>
                    <v-container v-if="$vuetify.breakpoint.smAndUp">
                        <v-row>
                            <v-col cols="12" md="6" offset-md="3">
                                <v-text-field
                                    prepend-inner-icon="mdi-magnify"
                                    label="What are you Looking ?"
                                    solo
                                    rounded
                                    disabled
                                >
                                    <template v-slot:append>
                                        <div class="my-2">
                                            <v-btn rounded color="primary" disabled
                                                >Search</v-btn
                                            >
                                        </div>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 10" :offset="$vuetify.breakpoint.xsOnly ? 0 : 1">
                <base-card class="-m-10">
                    <v-card-text>
                        <v-tabs v-model="tab">
                            <v-tab
                                v-for="item in items"
                                :key="item.tab"
                                class="body-2 font-weight-medium "
                                >{{ item.tab }}</v-tab
                            >
                        </v-tabs>

                        <v-tabs-items flat v-model="tab">
                            <v-tab-item v-for="item in items" :key="item.tab">
                                <v-card flat>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="6">
                                                <v-expansion-panels
                                                    accordion
                                                    flat
                                                >
                                                    <v-expansion-panel
                                                        v-for="(item,
                                                        i) in currentItems"
                                                        :key="i"
                                                        flat
                                                    >
                                                        <v-expansion-panel-header
                                                            v-if="i%2==0"
                                                            class="font-weight-bold"
                                                            >{{
                                                                item.tab
                                                            }}</v-expansion-panel-header
                                                        >
                                                        <v-expansion-panel-content
                                                            >{{
                                                                item.content
                                                            }}</v-expansion-panel-content
                                                        >
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-expansion-panels
                                                    accordion
                                                    flat
                                                >
                                                    <v-expansion-panel
                                                        v-for="(item,
                                                        i) in currentItems"
                                                        :key="i"
                                                        flat
                                                    >
                                                        <v-expansion-panel-header
                                                            v-if="i%2==1"
                                                            class="font-weight-bold"
                                                            >{{
                                                                item.tab
                                                            }}</v-expansion-panel-header
                                                        >
                                                        <v-expansion-panel-content
                                                            >{{
                                                                item.content
                                                            }}</v-expansion-panel-content
                                                        >
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </base-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: ['page'],
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'FAQ'
    },
    mounted(){
        if(this.page == "signup"){
            this.tab = 1;
        } else if(this.page == "support"){
            this.tab = 4;
        }
    },
    data() {
        return {
            tab: null,
            items: [
                {tab: 'Sign Up/Register', content: 'Tab 1 Content'},
                {tab: 'Game Rules', content: 'Tab 2 Content'},
                {tab: 'Leagues', content: 'Tab 2 Content'},
                {tab: 'Predictions', content: 'Tab 2 Content'},
                {tab: 'Support', content: 'Tab 1 Content'},
            ],
            itemsTwo: [
                {
                    tab: 'Who can play?',
                    group: 'register',
                    content: "Any resident of the Republic of Ireland aged over 18 is eligible to play. We hope include residents in Northern Ireland as soon as possible."
                },
                {
                    tab: 'I can’t sign in. What do I do?',
                    group: 'register',
                    content: "Please ensure you are using your registered e-mail address and correct password. Note that the password is case sensitive. If you have forgotten your password, click ‘Forgot Password’ and a link to rest your password will be sent to your registered email address (If you do not receive the password reminder, please check your Junk folder in your email inbox). "
                },
                {
                    tab: 'How does the game work?',
                    group: 'rules',
                    content: "The aim of the game is to correctly predict the outcome and margin of victory in each of the selected games. Scoring: •	Correct outcome – i.e. Home Win / Draw / Away Win: 5pts •	Correct winning margin i.e. 1-2pts / 3-4pts / 5-6pts / 7-8pts / 9+: 8pts *A correct Draw prediction automatically results in a correct winning margin: 17pts."
                },
                {
                    tab: 'How does the pricing structure work (Hurling and Football Games only)?',
                    group: 'rules',
                    content: "The game costs €10 to enter (payment services fees not included). If 50 or more people enter from any one GAA club (the club selected when signing up), 90% of that €10 is returned to the club (minus payment processing fee approx 50c). If less than 50 people from a GAA club enter, 0% of the €10 is returned to the club. Example: 500 players enter from Ballyboden St. Enda’s (i.e. 500 players have selected Ballyboden St. Enda’s as their club when signing up). In this instance, €4,500 is returned to Ballyboden St. Enda’s. (minus payment processing fee charged to Know The Game)"
                },
                {
                    tab: 'How do I join / create a league?',
                    group: 'leagues',
                    content: "When signing up, each player is asked to “select club”. Once a club is selected, the player is automatically entered into a club mini-league. A player can create a mini-league by going to Leagues > Create Mini-League. A player can join a mini-league by going to Leagues > Join Mini-League and entering the appropriate code (as sent to mini-league creator). "
                },
                {
                    tab: 'Can I change my predictions?',
                    group: 'predictions',
                    content: "A player can change their predictions by going to Selections > Current > More > Edit Selections. Predictions can be changed up to one hour before the start of Gameweek."
                },
                {
                    tab: 'Can I see other player’s prediction?',
                    group: 'predictions',
                    content: "A player can only see other player’s predictions once they have locked in their predictions. To lock in predictions, go to Selections > Current > More > Lock Selections. Once predictions are locked in, a player can no longer change their predictions."
                },
                {
                    tab: 'Can I see other player’s scores from previous Gameweeks?',
                    group: 'predictions',
                    content: "A player can see other player’s scores by going to Selections > History and using the search bars to filter results."
                },
                {
                    tab: 'Can I change my name?',
                    group: 'register',
                    content: "A player can change their account name by going to the profile symbol in the top right of the screen and clicking the symbol next to the player name."
                },
                {
                    tab: 'Can I change my club?',
                    group: 'register',
                    content: "A player can change their club by going to the profile symbol in the top right of the screen and clicking the symbol next to the club. Be aware, you can only be a member of one club league at a time, and the league you are in when the entries close is what will determine which club benefits from your entry fee"
                },
                {
                    tab: 'Contact',
                    group: 'support',
                    content: "Email: support@knowthegame.ie"
                }
            ]
        }
    },
    computed: {
        currentItems(){
            return this.itemsTwo.filter(it => it.group == this.currentTab);
        },
        currentTab(){
            if(this.tab == 0){
                return 'register'
            } else if(this.tab == 1){
                return 'rules'
            } else if(this.tab == 2){
                return 'leagues'
            } else if(this.tab == 3){
                return 'predictions'
            } else if(this.tab == 4){
                return 'support'
            } else {
                return ''
            }
        }
    }
}
</script>
<style lang="scss" scoped></style>
